import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"


const NomidmanCharactorDesign = () => {
  return (
    <section className="cs-nomidman-main">
        <Container>
            <Row className="align-items-center">
                <Col lg={6} className="mb-lg-0 mb-4">
                    <h2 className="secondary-header">Character<br /> Design</h2>
                    <p className="explore_content cs-character-design-content">Most animated videos that have characters are often driven by them. In this case the Nomidman platform was the focal point but we needed characters as part of the story. We created a new style that was simple yet quirky, something that wouldn’t stand out but seamlessly integrate itself into the video used in conjunction with the icons and movements.</p>
                </Col>
                <Col lg={6}>
                    <StaticImage src="../../../images/case-studies/nomidman/Characters.png" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={423} height={548}/>
                </Col>                                                
            </Row>
        </Container>
    </section> 
  )
}

export default NomidmanCharactorDesign
