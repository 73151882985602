import { StaticImage } from 'gatsby-plugin-image'
import React,{useState} from 'react'
import ModalVideo from 'react-modal-video'

const NomidmanExplainerVideo = () => {

	const [isOpen, setOpen] = useState(false)

	return (
		<React.Fragment>
			<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="ZKevAY0qsvg" onClose={() => setOpen(false)} />

			<button className='clickVideo' onClick={()=> setOpen(true)}>
                <div className='box position-relative'>
                    <StaticImage src="../../../images/case-studies/nomidman/explainer-video.jpeg" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={424} height={441}/>
                </div>
            </button>
		</React.Fragment>
	)
}

export default NomidmanExplainerVideo