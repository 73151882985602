import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import NomidmanOverviewVideo from "./nomidmanOverviewVideo"
import NomidmanExplainerVideo from "./nomidmanExplainerVideo"
import { Col, Container, Row } from "react-bootstrap"


const NomidmanVideoPreview = () => {
  return (
    <section className="cs-nomidman-main nm-video">
        <Container>
            <Row>
                <Col lg={5} className="mb-5">
                    <NomidmanOverviewVideo />
                </Col>
                <Col lg={6} className="offset-lg-1 mb-5">
                    <h2 className="secondary-header">Nomidman <br />Overview Video</h2>
                    <p className="explore_content mb-0">The overview video would be the video that introduced Nomidman to viewers and potential users. This video would explain how the need for Nomidman arose and why it is the single best global decentralized trading platform in the world.</p>
                </Col>
                <Col lg={6} className="mb-lg-0 mb-5">
                    <h2 className="secondary-header">Nomidman <br />Explainer Video</h2>
                    <p className="explore_content mb-0">The explainer video expanded on the overview video. As a slightly longer video, this was able to explain and go in to detail to show the viewers the many features that Nomidman has which make it the best trading platform in the marketplace.</p>
                </Col>
                <Col lg={5}>
                    <NomidmanExplainerVideo />
                </Col>
            </Row>
            <Row className="mt-5 pt-5">
                <Col lg={6} className="mb-4">
                    <StaticImage src="../../../images/case-studies/nomidman/thumb1.jpeg" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={2082} height={1284}/>
                </Col>
                <Col lg={6} className="mb-4">
                    <StaticImage src="../../../images/case-studies/nomidman/thumb2.jpeg" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={2082} height={1284}/>
                </Col>
                <Col lg={6} className="mb-lg-0 mb-4">
                    <StaticImage src="../../../images/case-studies/nomidman/thumb3.jpeg" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={2082} height={1284}/>
                </Col>
                <Col lg={6}>
                    <StaticImage src="../../../images/case-studies/nomidman/thumb4.jpeg" alt="Nomidman Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={2082} height={1284}/>
                </Col>                                                
            </Row>
        </Container>
    </section> 
  )
}

export default NomidmanVideoPreview
