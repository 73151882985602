import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const NomidmanWebsite = () => {
  return (
    <section className="cs-nomidman-main website-cs pt-0">
        <Container>
            <Col lg={12} className="p-0">
                <StaticImage src="../../../images/case-studies/nomidman/Website.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={1170} height={1191} />
            </Col>
        </Container>

        <Container>
            <Row className="wcs-content" >
                <Col lg={4} className="mt-5">
                    <h2 className="secondary-header" >Website</h2>
                </Col>
                <Col lg={8}>
                    <p className="explore_content">The website that we created was less of a revamp and more of a ground up effort. To streamline all their peripherals we ensured that the characters and the icons from the video were used to bring colour to their website and connect the videos to the website.</p>
                </Col>
            </Row>

            <Row>
                <div className="col-md-6 no-padding-mobile">                    
                    <StaticImage src="../../../images/case-studies/nomidman/Pages-02.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={1180} height={1381} />
                    <p className="challenge-quote res-challenge-quote" >It eliminates
                    the middle man in transactions, offering a platform that has an environment of perfect compitition</p>
                </div>
                <div className="col-md-6 no-padding-mobile">
                    <StaticImage src="../../../images/case-studies/nomidman/pages-full.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={1230} height={2101} />
                </div>
            </Row>

            <Row className="align-items-center">
                <Col lg={6}>
                    <StaticImage src="../../../images/case-studies/nomidman/iPhone.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={1429} height={2103} />
                </Col> 
                <Col lg={6}>
                    <h2 className="secondary-header">Mobile Design</h2>
                    <p className="explore_content mb-0">The objective of Nomidman was to create the world’s number one decentralized peer to peer trading platform. For this we created a responsive mobile website that would enable buyers and sellers to access the platform anytime, anywhere.</p>
                </Col> 
            </Row>
        </Container>
    </section>    
  )
}

export default NomidmanWebsite
