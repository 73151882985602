import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const NomidmanInfo = () => {
  return (
    <section className="client-details pb-0">
        <Container>
            <Row className="align-items-center">
                <Col lg={5} className="project-details">
                    <div className="cd-info">
                        <p className="title text-white" >Client</p>
                        <p className="name mid-block text-white">Nomidman</p>
                    </div>
                    <div className="cd-info">
                        <p className="title text-white">Project</p>
                        <p className="name text-white">Product Overview</p>
                    </div>
                </Col>
                <Col lg={7}>
                    
                        <p className="explore_content">
                            Nomidman is the world’s first autonomous peer to peer market hub powered by ethereum blockchain. It eliminates the middle man in transactions, offering a platform that has an environment of perfect competition with a number of industry firsts that help it stand out from the rest of the crowd
                        </p>
                   
                        <p className="explore_content">Nomidman came to us just as they were finishing up their product. They wanted us to help them bring awareness to their product by showing people the Nomidman ecosystem and all its amazing benefits. They were looking for a video and a reworking of their website. And this is what we came up with!</p>
                </Col>
            </Row>
        </Container>
        <div className="bottom-image"></div>
    </section>       
  )
}

export default NomidmanInfo
