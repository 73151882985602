import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const NomidmanChallange = () => {
  return (
        
    <section className="cs-nomidman-main challange">
        <Container>
            <Row>
                <Col md={12}>
                    <h2 className="secondary-header">The <br />Challenge</h2>
                </Col>
                <Col md={12}>
                    <Row>
                        <Col lg={5}>
                            <StaticImage src="../../../images/case-studies/nomidman/quotes-right.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width quotes-img" width={60} height={48} />
                            <p className="challenge-quote">"Our videos and the website revamp had to convey the message that Nomidman’s creators envisioned. Any deviation could cause problems for their business."</p>
                        </Col>
                        <Col lg={2} className="col-md-2 img-guy text-center">
                            <StaticImage src="../../../images/case-studies/nomidman/Guy1.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={127} height={281} />
                        </Col>

                        <Col lg={5} className="no-padding-mobile">
                            
                                <p className="explore_content">
                                    Nomidman had revolutionary ideas but no way to show them to the world. They approached us with ideas to make longer videos and we convinced them that shorter ones worked better.
                                    Some of their ideas had to become reality through the video and that is where we came in.
                                </p>
                         
                                <p className="explore_content">
                                    Their existing website was a very basic design that had a major brand disconnect with their brand identity. It also stuck out when compared to the videos that we were going to create.
                                    We had to tie the videos to the website making sure that they were streamlined and managed to stand out from the many websites online.
                                </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className="solution-wrapper">
                    <Row className="align-items-center">
                        <Col lg={5} className="img-guy">
                            <StaticImage src="../../../images/case-studies/nomidman/Guy2.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid cs-image-min-width" width={300} height={292} />
                        </Col>
                        <Col lg={7} className="no-padding-mobile">
                            <h2 className="secondary-header">The <br />Solution</h2>
                            <p className="explore_content mb-0">
                                We created an overview video that introduced the viewers to Nomidman in a way that was relatable and understandable, 
                                essentially simplifying a complicated concept, to its bare bones. This video helped viewers understand the essence of Nomidman and what they were trying to accomplish. The second video was the explainer video and highlighted all their features which are important in their claim as the world’s first blockchain powered decentralized trading platform. This video went into detail about all the things that Nomidman had that made it so unique in the marketplace. Both the videos consisted of original characters and styles created for Nomidman along with a crisp and clear voiceover that explained what Nomidman did.
                                This way, viewers would not make mistakes or misinterpret things about Nomidman. What they say was what they got.
                            </p>
                        </Col>
                    </Row> 
                </Col>
                <Col lg={12} className="pt-4">
                    <div className="inner p-0">
                        <p className="explore_content">
                            We then focused on their website. Our work on the website was less of a revamp and more of a ground-up effort. To streamline all their peripherals that we had delivered, we ensured that the characters and the icons from the video were used to bring colour to their website and connect the videos to the website. 
                            And for those who had watched the videos, icons and design styles from the videos were used in the website, forming a connection. Along with a simple and uncluttered design, the website stood out and ensured that any visitor would be able to access it and go through all of the information easily. 
                        </p>
                        <p className="explore_content mb-0">
                            Websites these days have to be quirky, informative, easy-to-use, optimized for easy loading and so much more. Nomidman's new website was indeed all that and more.
                            This combined package of the overview video, explainer video and website completely shifted fortunes for Nomidman and established their online presence as a serious company with lofty but realistic goals that aimed to disrupt the global marketplace.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section> 
  )
}

export default NomidmanChallange
