import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const NomidmanTestimonials = () => {
  return (
    <section className="testimonial pb-0">
        <Container className="container">
            <Row className="justify-content-center">
                <Col lg={8} className="text-center">
                    <div className="t-content">
                        <blockquote>
                            <p>
                                "It was a pleasure working with WowMakers. They perfectly conveyed the message that we had, through the videos and the website design. We found them to be very <strong>creative and professional</strong>. They knew exactly what we wanted and they had the solution to our needs.
                                Thank you WowMakers for the great job and keep up the good work!"</p>
                        </blockquote>
                        <p className="mb-4">
                            <StaticImage src="../../../images/case-studies/nomidman/reem-taleb.png" alt="Nomidman UX/UI and Expaliner Video Case studies UX/UI Case studies" placeholder="blurred" className="img-fluid profile-circle cs-image-min-width" width={245} height={240} /> 
                        </p>

                        <span className="text-center"><strong>Reem Taleb</strong></span><br />
                        <small className="text-center">Director of Operations</small>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>          
  )
}

export default NomidmanTestimonials
