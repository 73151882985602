import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import NomidmanMain from "../../components/case-studies/nomidman/nomidmanMain"
import NomidmanInfo from "../../components/case-studies/nomidman/nomidmanInfo"
import NomidmanTestimonials from "../../components/case-studies/nomidman/nomidmanTestimonials"
import NomidmanChallange from "../../components/case-studies/nomidman/nomidmanChallange"
import NomidmanWebsite from "../../components/case-studies/nomidman/nomidmanWebsite"
import NomidmanVideoPreview from "../../components/case-studies/nomidman/nomidmanVideoPreview"
import NomidmanCharactorDesign from "../../components/case-studies/nomidman/nomidmanCharactorDesign"
import NomidmanMoreCs from "../../components/case-studies/nomidman/nomidmanMoreCs"
import DirectContact from "../../components/global/directContact"

import csNomidmanFeaturedImage from "../../../static/case-studies/nomidman/nomidman.jpeg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/nomidman.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csNomidmanFeaturedImage}
      title="Nomidman Case Study | UX/UI Design | Blockchain"
      description="Nomidman is the world’s first autonomous peer to peer market hub powered by Ethereum blockchain. They came to us to help them bring awareness to their product by showing people their ecosystem and all its amazing benefits. They were looking for a video and a reworking of their website."
    />
  </> 
)

const Nomidman = () => {
    return (
      <Layout>
        <Seo
          bid="cs-nomidman"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <NomidmanMain />
        <NomidmanInfo />
        <NomidmanTestimonials />
        <NomidmanChallange />
        <NomidmanWebsite />
        <NomidmanVideoPreview />
        <NomidmanCharactorDesign />
        <NomidmanMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default Nomidman
